/** override Sidea */

import { PureComponent } from 'react';

import './CloseIcon.style';

/** @namespace Frontend/Component/CloseIcon/Component */
export class CloseIcon extends PureComponent {
    render() {
        return (
            <svg
              block="CloseIcon"
              width="21.184"
              height="21.184"
              viewBox="0 0 21.184 21.184"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <g transform="translate(0.707 0.707)">
                    <g transform="translate(0 0)">
                        <rect width="26.729" height="1.229" transform="translate(0 18.9) rotate(-45)" strokeWidth="1" />
                    </g>
                    <g transform="translate(0 0)">
                        <rect width="1.229" height="26.729" transform="translate(0 0.869) rotate(-45)" strokeWidth="1" />
                    </g>
                </g>
            </svg>
        );
    }
}

export default CloseIcon;
