import {
    Field as SourceField
} from 'SourceComponent/Field/Field.component';

import './Field.override.style';

/** @namespace Frontend/Component/Field/Component */
export class Field extends SourceField {
    renderErrorMessage(message, key) {
        return <div block="Field" elem="ErrorMessage" key={ key }>{ __(message) }</div>;
    }
}

export default Field;
