/**
 * Store Finder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { SWITCH_ITEMS_TYPE } from 'Component/Router/Router.config';

import { STORE_FINDER_PAGE_URL } from '../config/StoreFinder.config';

export const StoreFinder = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "storeFinder" */
    '../route/StoreFinder'
));
export const StoreDetails = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "StoreDetails" */
    '../route/StoreDetails'
));

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
export const addStoresRoutes = (originalMember) => [
    ...originalMember,
    {
        component: <Route path={ STORE_FINDER_PAGE_URL } exact component={ StoreFinder } />,
        position: 90
    },
    {
        component: <Route path={ `${STORE_FINDER_PAGE_URL}/:identifier` } component={ StoreDetails } />,
        position: 89
    }
];

export const config = {
    'Component/Router/Component': {
        'member-property': {
            [SWITCH_ITEMS_TYPE]: addStoresRoutes
        }
    }
};

export default config;
