import { connect } from 'react-redux';

import {
    FieldSelectContainer as SourceFieldSelectContainer
} from 'SourceComponent/FieldSelect/FieldSelect.container';
import { DeviceType } from 'Type/Device.type';

/** @namespace Frontend/Component/FieldSelect/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Frontend/Component/FieldSelect/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({

});

/** @namespace Frontend/Component/FieldSelect/Container */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    static propTypes = {
        ...SourceFieldSelectContainer.propTypes,
        device: DeviceType.isRequired
    };

    containerProps() {
        const { device } = this.props;

        return {
            ...super.containerProps(),
            device
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldSelectContainer);
