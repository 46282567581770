/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';

import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { fetchQuery } from 'Util/Request';

import { URL_PREFIX } from '../../component/MyReturnsTab/MyReturnsTab.config';
import AmastyMyReturnsQuery from '../../query/AmastyMyReturns.query';
import { getOrderOption } from '../../util/Utils';
import AmastyMyReturnsPage from './AmastyMyReturnsPage.component';
import { LOGIN_URL, REQUEST_INCREMENT_ID_LENGTH } from './AmastyMyReturnsPage.config';

/** @namespace Scandiweb/AmastyRma/Route/AmastyMyReturnsPage/Container */
export class AmastyMyReturnsPageContainer extends PureComponent {
    static propTypes = {
    };

    containerFunctions = {
        getOrderLink: this.getOrderLink.bind(this),
        setOrderLink: this.setOrderLink.bind(this),
        getRequestQty: this.getRequestQty.bind(this),
        getRequestIncrementId: this.getRequestIncrementId.bind(this)
    };

    componentDidMount() {
        if (!isSignedIn()) {
            history.push(LOGIN_URL);
        }

        this.loadState();
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            isLoading: true
        };
    }

    async loadState() {
        const {
            getAmRmaOrderList,
            getAmRmaReturnsForCurrentCustomer,
            getAmRmaSettings
        } = await this.fetchReturns();

        this.setState({
            getAmRmaOrderList,
            getAmRmaReturnsForCurrentCustomer,
            getAmRmaSettings,
            ordersOptions: getAmRmaOrderList.map(getOrderOption),
            orderLink: this.getInitialOrderLink(getAmRmaOrderList, getAmRmaOrderList[0].order_id),
            isLoading: false
        });
    }

    async fetchReturns() {
        const myReturnsQuery = await fetchQuery([
            AmastyMyReturnsQuery.getSettingsField(),
            AmastyMyReturnsQuery.getOrderListField(),
            AmastyMyReturnsQuery.getReturnsForCustomerField()
        ]);

        const { getAmRmaReturnsForCurrentCustomer } = myReturnsQuery;
        const orderIncrementIdSet = new Set(getAmRmaReturnsForCurrentCustomer.map(
            ({ order_increment_id }) => order_increment_id
        ));
        const ordersQuery = await fetchQuery(
            AmastyMyReturnsQuery.getCustomerOrdersField(orderIncrementIdSet)
        );

        const {
            customer: {
                orders: {
                    items: orders
                }
            }
        } = ordersQuery;
        const skuSet = new Set(orders.map(({ items: [{ product_sku = '' }] }) => product_sku));

        const {
            products: {
                items: products
            }
        } = await fetchQuery(
            AmastyMyReturnsQuery.getProductsField(skuSet)
        );

        return this.addProductImagesToRequests({ myReturnsQuery, orders, products });
    }

    addProductImagesToRequests({ myReturnsQuery, orders, products }) {
        const { getAmRmaReturnsForCurrentCustomer } = myReturnsQuery;
        const modifiedReturnsQuery = myReturnsQuery;
        modifiedReturnsQuery.getAmRmaReturnsForCurrentCustomer = getAmRmaReturnsForCurrentCustomer.map(
            (returnRequest) => {
                const { order_increment_id: request_increment_id } = returnRequest;
                const newReturnRequest = returnRequest;
                newReturnRequest.small_image = this.getImageUrl({ orders, request_increment_id, products });
                return newReturnRequest;
            }
        );

        return modifiedReturnsQuery;
    }

    getRequestIncrementId(request_id) {
        return `${request_id.toString().padStart(REQUEST_INCREMENT_ID_LENGTH, '0')}`;
    }

    getImageUrl({ orders, request_increment_id, products }) {
        return orders.reduce((acc,
            {
                increment_id,
                items: [
                    { product_sku = '' }
                ]
            }) => {
            if (increment_id !== request_increment_id) {
                return acc;
            }

            return products.reduce((
                acc,
                {
                    sku,
                    small_image: {
                        url:
                imageUrl = ''
                    } = {}
                }
            ) => (sku === product_sku ? imageUrl : acc), '');
        }, '');
    }

    getInitialOrderLink(orderList, value) {
        if (!orderList?.length) {
            return '';
        }

        return this.getOrderLink(value);
    }

    getOrderLink(value) {
        return `/${ URL_PREFIX }/account/newreturn/order/${value}`;
    }

    setOrderLink(value) {
        this.setState({
            orderLink: this.getOrderLink(value)
        });
    }

    getRequestQty(request_items) {
        return request_items.reduce((sum, { qty }) => sum + qty, 0);
    }

    containerProps() {
        const {
            getAmRmaOrderList,
            getAmRmaReturnsForCurrentCustomer,
            getAmRmaSettings,
            isLoading,
            orderLink,
            ordersOptions,
            isCollapsed
        } = this.state;

        return {
            getAmRmaOrderList,
            getAmRmaReturnsForCurrentCustomer,
            getAmRmaSettings,
            isLoading,
            orderLink,
            ordersOptions,
            isCollapsed
        };
    }

    render() {
        return (
            <AmastyMyReturnsPage
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default AmastyMyReturnsPageContainer;
