import PropTypes from 'prop-types';

import CmsBlockQuery from 'Query/CmsBlock.query';
import {
    CmsBlockContainer as SourceCmsBlockContainer
} from 'SourceComponent/CmsBlock/CmsBlock.container';

import CmsBlock from './CmsBlock.component';

/** @namespace Frontend/Component/CmsBlock/Container */
export class CmsBlockContainer extends SourceCmsBlockContainer {
    static propTypes = {
        identifier: PropTypes.string.isRequired,
        blockType: PropTypes.string
    };

    state = {
        cmsBlock: {}
    };

    __construct(props) {
        super.__construct(props, 'CmsBlockContainer');
    }

    containerProps() {
        const { blockType } = this.props;
        const { cmsBlock } = this.state;

        return { cmsBlock, blockType };
    }

    componentDidMount() {
        this._getCmsBlock();
    }

    // funzione rimossa per risolvere momentaneamente il problema della visualizzazione dei blocchi cms
    // componentDidUpdate(prevProps) {
    //     const { identifier } = this.props;
    //     const { identifier: prevIdentifier } = prevProps;
    //
    //     if (identifier !== prevIdentifier) {
    //         this._getCmsBlock();
    //     }
    // }

    componentDidUpdate() {
        this._getCmsBlock();
    }

    _getCmsBlock() {
        const { identifier } = this.props;

        this.fetchData(
            [CmsBlockQuery.getQuery({ identifiers: [identifier] })],
            ({ cmsBlocks: { items } }) => {
                if (!items.length) {
                    return;
                }

                this.setState({ cmsBlock: items[0] });
            }
        );
    }

    render() {
        return (
            <CmsBlock
              { ...this.containerProps() }
            />
        );
    }
}

export default CmsBlockContainer;
