/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

import { AmastyRmaSettingsQuery } from './AmastyRmaSettings.query';

/** @namespace Scandiweb/AmastyRma/Query/AmastyNewReturn/Query */
export class AmastyNewReturnQuery extends AmastyRmaSettingsQuery {
    getSettingsField() {
        return new Field('getAmRmaSettings')
            .addFieldList(this.getSettingsFields());
    }

    getOrderByIdField(order_increment_id) {
        return new Field('customer')
            .addFieldList(this.getOrderByIdFields(order_increment_id));
    }

    getProductsField(skuSet) {
        return new Field('products')
            .addArgument('filter', 'ProductAttributeFilterInput', { sku: { in: Array.from(skuSet) } })
            .addField(this.getProductsItemsField());
    }

    getProductsItemsField() {
        return new Field('items')
            .addFieldList(this.getProductsItemsFields());
    }

    getSmallImageField() {
        return new Field('small_image')
            .addField('url');
    }

    getCustomFieldsField() {
        return new Field('customFields')
            .addFieldList(this.getCustomFieldsFields());
    }

    getOrdersField(order_increment_id) {
        return new Field('orders')
            .addArgument('filter', 'CustomerOrdersFilterInput', { number: { eq: order_increment_id } })
            .addField(this.getOrdersItemsField());
    }

    getOrdersItemsField() {
        return new Field('items')
            .addFieldList(this.getOrdersItemsFields());
    }

    getOrderItemsField() {
        return new Field('items')
            .addFieldList(this.getOrderItemsFields());
    }

    getAmRmaOrderItemField() {
        return new Field('amrma_order_item')
            .addFieldList(this.getAmRmaOrderItemFields());
    }

    getResolutionsField() {
        return new Field('resolutions')
            .addFieldList(this.getAllResolutionsFields());
    }

    getSelectedOptionsField() {
        return new Field('selected_options')
            .addFieldList(this.getSelectedOptionsFields());
    }

    getBillingAddressField() {
        return new Field('billing_address')
            .addFieldList(this.getBillingAddressFields());
    }

    getOrderByIdFields(order_increment_id) {
        return [
            'firstname',
            'lastname',
            'email',
            this.getOrdersField(order_increment_id)
        ];
    }

    getProductsItemsFields() {
        return [
            'sku',
            this.getSmallImageField()
        ];
    }

    getCustomFieldsFields() {
        return [
            'code',
            'title'
        ];
    }

    getOrdersItemsFields() {
        return [
            'increment_id',
            'id',
            this.getOrderItemsField(),
            this.getBillingAddressField()
        ];
    }

    getOrderItemsFields() {
        return [
            this._getConfigurableItem(),
            'quantity_invoiced',
            this.getAmRmaOrderItemField(),
            'product_name',
            'product_sku',
            this.getSelectedOptionsField()
        ];
    }

    _getConfigurableItem() {
        return new Field('configurable_item')
            .addFieldList(this._getOrderConfigurableItemField());
    }

    _getOrderConfigurableItemField() {
        return [
            this._getMediaGalleryConfigurable(),
            'manufacturer'
        ];
    }

    _getMediaGalleryConfigurable() {
        return new Field('media_gallery')
            .addFieldList(this._getOrderMediaGalleryConfigurableField());
    }

    _getOrderMediaGalleryConfigurableField() {
        return [
            'url',
            'position',
            'label'
        ];
    }

    getAmRmaOrderItemFields() {
        return [
            'order_item_id',
            'available_qty',
            'purchased_qty',
            'is_returnable',
            'no_returnable_reason',
            'already_returned_requests',
            this.getResolutionsField()
        ];
    }

    getAllResolutionsFields() {
        return [
            'resolution_id',
            'label',
            'status',
            'position'
        ];
    }

    getSelectedOptionsFields() {
        return [
            'label',
            'value'
        ];
    }

    getBillingAddressFields() {
        return [
            'firstname',
            'lastname',
            'street',
            'city',
            'region',
            'postcode',
            'country_code',
            'telephone'
        ];
    }

    getSettingsFields() {
        return [
            'isReturnPolicyEnabled',
            'policyUrl',
            'customFieldsBlockTitle',
            this.getCustomFieldsField(),
            this.getAllReasonsField(),
            this.getAllResolutionsField(),
            this.getAllConditionsField()
        ];
    }
}

export default new AmastyNewReturnQuery();
