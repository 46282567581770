import {
    FieldSelect as SourceFieldSelect
} from 'SourceComponent/FieldSelect/FieldSelect.component';
import { DeviceType } from 'Type/Device.type';

import './FieldSelect.style';

/** @namespace Frontend/Component/FieldSelect/Component */
export class FieldSelect extends SourceFieldSelect {
    static propTypes = {
        ...SourceFieldSelect.propTypes,
        device: DeviceType.isRequired
    };

    renderNativeOption(option) {
        const {
            id,
            value,
            disabled,
            label,
            subLabel = '',
            isAvailable = true,
            isPlaceholder
        } = option;

        const { isDisabled, device: { isMobile } } = this.props;

        if (isMobile) {
            return (
                <option
                  key={ id }
                  id={ id }
                  value={ value }
                  disabled={ disabled || isDisabled }
                  selected={ isPlaceholder }
                >
                   { `${label} ${subLabel}` }
                </option>
            );
        }

        return (
            <option
              key={ id }
              id={ id }
              value={ value }
              disabled={ disabled || isDisabled || !isAvailable }
              selected={ isPlaceholder }
            >
               { `${label} ${subLabel}` }
            </option>
        );
    }
}

export default FieldSelect;
