/* Override Sidea */

// import Link from 'Component/Link';
import {
    Notification as SourceNotification
} from 'SourceComponent/Notification/Notification.component';

import './Notification.override.style';

/** @namespace Frontend/Component/Notification/Component */
export class Notification extends SourceNotification {
    render() {
        const { notification, id } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;

        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing'
        };

        const message = msgText.value || msgText;
        const translateMessage = __(message);
        /* eslint-disable-next-line react/no-danger */
        const finalMessage = <div dangerouslySetInnerHTML={ { __html: translateMessage } } />;

        return (
            <div block="Notification" mods={ mods } ref={ this.notification } id={ id }>
                <button block="Notification" elem="Button" onClick={ this.hideNotification }> Close </button>
                <p block="Notification" elem="Text">
                    { finalMessage }
                </p>
                { this.renderDebug() }
            </div>
        );
    }
}

export default Notification;
