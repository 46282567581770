/**
 * Store Finder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { STORE_DETAILS_HEADER_TAG, STORE_FINDER_HEADER_TAG } from '../config/StoreFinder.config';

const aroundStateMap = (originalMember) => ({
    ...originalMember,
    [STORE_FINDER_HEADER_TAG]: {
        back: true,
        title: true
    },
    [STORE_DETAILS_HEADER_TAG]: {
        back: true,
        title: true
    }
});

export const config = {
    'Component/Header/Component': {
        'member-property': {
            stateMap: aroundStateMap
        }
    }
};

export default config;
