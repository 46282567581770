/**
 * Store Finder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const UPDATE_STORE_FINDER = 'UPDATE_STORE_FINDER';
export const UPDATE_IS_STORE_FINDER_LOADING = 'UPDATE_IS_STORE_FINDER_LOADING';

/**
 * @namespace StoreFinder/Store/StoreFinder/Action/updateStoreFinder */
export const updateStoreFinder = (value) => ({
    type: UPDATE_STORE_FINDER,
    value
});

/** @namespace StoreFinder/Store/StoreFinder/Action/setIsLoading */
export const setIsLoading = (value) => ({
    type: UPDATE_IS_STORE_FINDER_LOADING,
    value
});
