import PropTypes from 'prop-types';

import {
    Overlay as SourceOverlay
} from 'SourceComponent/Overlay/Overlay.component';

/** @namespace Frontend/Component/Overlay/Component */
export class Overlay extends SourceOverlay {
    static propTypes = {
        ...SourceOverlay.propTypes,
        filterClick: PropTypes.bool.isRequired
    };

    getIsVisible(props = this.props) {
        const {
            id, activeOverlay, isStatic, filterClick
        } = props;

        return isStatic || id === activeOverlay || filterClick;
    }
}

export default Overlay;
