/* Override Sidea */

import {
    ANIMATION_DURATION as SOURCE_ANIMATION_DURATION,
    ERROR_TYPE as SOURCE_ERROR_TYPE
} from 'SourceComponent/Notification/Notification.config';

export const ANIMATION_DURATION = SOURCE_ANIMATION_DURATION;
export const NOTIFICATION_LIFETIME = 10000;
export const ERROR_NOTIFICATION_LIFETIME = 5000;
export const ERROR_TYPE = SOURCE_ERROR_TYPE;
