/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

import { AmastyRmaSettingsQuery } from './AmastyRmaSettings.query';

/** @namespace Scandiweb/AmastyRma/Query/AmastyViewReturn/Query */
export class AmastyViewReturnQuery extends AmastyRmaSettingsQuery {
    getSettingsField() {
        return new Field('getAmRmaSettings')
            .addFieldList(this.getSettingsFields());
    }

    getReturnByIdField(request_id) {
        return new Field('getAmRmaReturnById')
            .addArgument('request_id', 'Int!', request_id)
            .addFieldList(this.getAmRmaReturnByIdFields());
    }

    getCustomerOrderByIdField(order_increment_id) {
        return new Field('customer')
            .addFieldList(this.getCustomerOrderByIdFields(order_increment_id));
    }

    getReturnInstructionsField(status_id) {
        return new Field('getAmRmaReturnInstruction')
            .addArgument('status_id', 'Int!', status_id);
    }

    getProductsField(skuSet) {
        return new Field('products')
            .addArgument('filter', 'ProductAttributeFilterInput', { sku: { in: Array.from(skuSet) } })
            .addField(this.getProductsItemsField());
    }

    getCustomFieldsField() {
        return new Field('customFields')
            .addFieldList(this.getCustomFieldsFields());
    }

    getStatusField() {
        return new Field('status')
            .addFieldList(this.getStatusFields());
    }

    getRequestItemsField() {
        return new Field('request_items')
            .addFieldList(this.getRequestItemsFields());
    }

    getTrackingNumbersField() {
        return new Field('tracking_numbers')
            .addFieldList(this.getTrackingNumbersFields());
    }

    getOrdersField(order_increment_id) {
        return new Field('orders')
            .addArgument('filter', 'CustomerOrdersFilterInput', { number: { eq: order_increment_id } })
            .addField(this.getOrdersItemsField());
    }

    getOrdersItemsField() {
        return new Field('items')
            .addFieldList(this.getOrdersItemsFields());
    }

    getOrderItemsField() {
        return new Field('items')
            .addFieldList(this.getOrderItemsFields());
    }

    getBillingAddressField() {
        return new Field('billing_address')
            .addFieldList(this.getBillingAddressFields());
    }

    getProductsItemsField() {
        return new Field('items')
            .addFieldList(this.getProductsItemsFields());
    }

    getSmallImageField() {
        return new Field('small_image')
            .addField('url');
    }

    getOrdersItemsFields() {
        return [
            this.getOrderItemsField(),
            this.getBillingAddressField()
        ];
    }

    getStatusFields() {
        return [
            'status_id',
            'state',
            'label',
            'title',
            'color'
        ];
    }

    getRequestItemsFields() {
        return [
            'request_item_id',
            'request_id',
            'order_item_id',
            'qty',
            'request_qty',
            'reason_id',
            'condition_id',
            'resolution_id',
            'product_name',
            'product_url_key',
            'item_status'
        ];
    }

    getTrackingNumbersFields() {
        return [
            'tracking_id',
            'request_id',
            'tracking_code',
            'tracking_number',
            'is_customer'
        ];
    }

    getOrderItemsFields() {
        return [
            'product_url_key',
            'product_sku',
            'product_name',
            this._getConfigurableItem(),
            this.getAmRmaOrderItemField()
        ];
    }

    getAmRmaOrderItemField() {
        return new Field('amrma_order_item')
            .addFieldList(this.getAmRmaOrderItemFields());
    }

    getAmRmaOrderItemFields() {
        return [
            'order_item_id'
        ];
    }

    _getConfigurableItem() {
        return new Field('configurable_item')
            .addFieldList(this._getOrderConfigurableItemField());
    }

    _getOrderConfigurableItemField() {
        return [
            this._getMediaGalleryConfigurable(),
            'manufacturer'
        ];
    }

    _getMediaGalleryConfigurable() {
        return new Field('media_gallery')
            .addFieldList(this._getOrderMediaGalleryConfigurableField());
    }

    _getOrderMediaGalleryConfigurableField() {
        return [
            'url',
            'position',
            'label'
        ];
    }

    getBillingAddressFields() {
        return [
            'firstname',
            'lastname',
            'street',
            'city',
            'region',
            'postcode',
            'country_code',
            'telephone'
        ];
    }

    getProductsItemsFields() {
        return [
            'sku',
            this.getSmallImageField()
        ];
    }

    getSmallImageFields() {
        return [
            'url'
        ];
    }

    getSettingsFields() {
        return [
            'isAskForFeedback',
            'customFieldsBlockTitle',
            this.getCustomFieldsField(),
            this.getAllReasonsField(),
            this.getAllResolutionsField(),
            this.getAllConditionsField()
        ];
    }

    getAmRmaReturnByIdFields() {
        return [
            'request_id',
            'order_id',
            'order_increment_id',
            'store_id',
            'created_at',
            'modified_at',
            'customer_id',
            'customer_name',
            'url_hash',
            'manager_id',
            'custom_fields',
            'rating',
            'rating_comment',
            'note',
            'shipping_label',
            this.getStatusField(),
            this.getRequestItemsField(),
            this.getTrackingNumbersField()
        ];
    }

    getCustomerOrderByIdFields(order_increment_id) {
        return [
            'firstname',
            'lastname',
            'email',
            this.getOrdersField(order_increment_id)
        ];
    }

    getErrorFields() {
        return [
            'error',
            'message'
        ];
    }

    getCustomFieldsFields() {
        return [
            'code',
            'title'
        ];
    }
}

export default new AmastyViewReturnQuery();
