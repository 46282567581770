/**
 * Store Finder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    UPDATE_IS_STORE_FINDER_LOADING,
    UPDATE_STORE_FINDER
} from './StoreFinder.action';

export const initialState = {
    storeListCities: [],
    storeListMapped: {},
    storeByName: {},
    storeById: {},
    storeList: [],
    isLoading: true
};

/** @namespace Scandiweb/StoreFinder/Store/StoreFinder/Reducer/StoreFinderReducer */
export const StoreFinderReducer = (state = initialState, action) => {
    const { value, type } = action;

    switch (type) {
    case UPDATE_IS_STORE_FINDER_LOADING:
        return { ...state, isLoading: value };
    case UPDATE_STORE_FINDER:
        const storeListObject = value.reduce(({
            storeListCities,
            storeListMapped,
            storeByName,
            storeById
        }, store) => {
            /* eslint-disable no-param-reassign */
            const {
                city,
                longitude,
                latitude,
                store_name,
                identifier
            } = store;

            if (!city || !longitude || !latitude) {
                return {
                    storeListCities,
                    storeListMapped,
                    storeByName,
                    storeById
                };
            }

            if (!storeListCities.includes(city)) {
                storeListCities.push(city);
            }

            if (!storeListMapped[city]) {
                storeListMapped[city] = [];
            }

            storeListMapped[city].push(store);
            storeByName[store_name] = store;
            storeById[identifier] = store;

            return {
                storeListCities,
                storeListMapped,
                storeByName,
                storeById
            };
            /* eslint-enable no-param-reassign */
        }, {
            storeListCities: [],
            storeListMapped: {},
            storeByName: {},
            storeById: {}
        });

        return {
            ...state,
            ...storeListObject,
            storeList: value
        };

    default:
        return state;
    }
};

export default StoreFinderReducer;
